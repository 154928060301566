body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #e0e0e0;
  background-color: #38424b;
}

#nav-bar-title {
  color: #e0e0e0;
}

#search-bar span {
  padding-top: 0;
}

.container {
  top:64px;
  right:0;
  bottom:0;
  left:0;
}

.client-summary {
  color: #000000;
  margin: 5px 0px 5px 0px;
}

.splash {
  position:fixed !important;
  position:absolute;
  top:64px;
  right:0;
  bottom:0;
  left:0;
  background-color: #000000;
}

.splash-logo {
  display: inline;
}

.splash-logo-img {
  width: 70%;
  height: calc(70% - 64px);
  margin: 15% 15% 15% 15%;
}
